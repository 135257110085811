import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectIsAuthenticated } from './auth.selectors';
import { AppState } from '../core.state';
import { MainService } from '../main.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private asm : MainService) {}

  canActivate(): Observable<boolean> {
    return Observable.create(this.asm.access_token != undefined);
  }
}
