import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MainService } from '@app/core/main.service';

@Component({
  selector: 'fidelizacion-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TarjetaComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  qrPath: string;
  eanPath: string;
  usaQR : boolean = false;
  usaBarcode : boolean = false;
  usaPin : boolean = false;
  pin : string = '';
  pathpdf : string = '';
  ngOnInit() {
    if (!this.asm.usuario) {
      this.asm.requesting++; this.cd.markForCheck();
      this.asm.obtenerUsuariosDatos().subscribe((ret) => {
        this.qrPath = this.asm.apiPath + "/ObtenerQRTarjeta/" + this.asm.usuario.tokenQR;
        this.eanPath = this.asm.apiPath + "/ObtenerEANTarjeta/" + this.asm.usuario.tokenQR;
        this.pathpdf = this.asm.apiPath + "/PDFTarjeta/" + this.asm.usuario.tokenPDF;
        this.usaQR = this.asm.usuario.usaQR;
        this.usaBarcode = this.asm.usuario.usaBarcode;
        this.usaPin  = this.asm.usuario.usaPin;
        this.pin  = this.asm.usuario.pin;
      
        this.asm.requesting--; this.cd.markForCheck();
      })
    }
    else {
      this.qrPath = this.asm.apiPath + "/ObtenerQRTarjeta/" + this.asm.usuario.tokenQR;
      this.eanPath = this.asm.apiPath + "/ObtenerEANTarjeta/" + this.asm.usuario.tokenQR;
      this.pathpdf = this.asm.apiPath + "/PDFTarjeta/" + this.asm.usuario.tokenPDF;
      this.usaQR = this.asm.usuario.usaQR;
      this.usaBarcode = this.asm.usuario.usaBarcode;
      this.usaPin  = this.asm.usuario.usaPin;
      this.pin  = this.asm.usuario.pin;
  }
  }

  constructor(public asm: MainService, private cd: ChangeDetectorRef) {


  }

}
