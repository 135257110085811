import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService, ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MainService } from '@app/core/main.service';
import { Fidelizaciones, Localidades } from '@app/core/modelo/modelo.negocio';
import { Observable } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'fidelizacion-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PerfilComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  form = this.fb.group({
    fide_NumeroDoc: ['', [Validators.required, Validators.maxLength(8), Validators.max(99999999)]],
    fide_Apellido: ['', [Validators.required]],
    fide_Nombre: ['', [Validators.required]],
    fide_email: ['', [Validators.required, Validators.email]],
    fide_TelMovil: ['', [Validators.required]],
   // fide_TelFijo: ['', []],
    fide_Nacimiento: ['', [Validators.required]],
    fide_DomiLocalidad: [''],
    fide_DomiCodigoPostal: [''],
    pcia_Codigo: [''],
    fide_RecibeNovedades: [],
    localidadesAutocomplete:[]
  });

  formPass = this.fb.group({
    fide_ClaveAccesoAnterior: ['', [Validators.required]],
    fide_ClaveAcceso: ['', [Validators.required]],
    fide_ClaveAcceso2: ['', [Validators.required]],

  });

  constructor(private fb: FormBuilder, public asm: MainService, private noti: NotificationService, private cd: ChangeDetectorRef) {


  }

  localidades: Localidades[] = [];
  provincias: [] = [];
loadInicialProvincias = true;
  ngOnInit() {
    if (!this.asm.usuario) {
      this.asm.requesting++; this.cd.markForCheck();
      this.asm.obtenerUsuariosDatos().subscribe((ret) => {

        this.asm.get("Provincias/0").subscribe((ret: any) => {
        this.provincias = ret;
            this.precargar();
        });//el /0 es porque no tengo pais para filtrar

        this.asm.requesting--;if(this.asm.requesting <0){this.asm.requesting=0;} this.cd.markForCheck();
      }, () => { this.asm.requesting--; if(this.asm.requesting <0){this.asm.requesting=0;}this.asm.logout(); })
    }
    else {
      this.asm.get("Provincias/0").subscribe((ret: any) => {
        this.provincias = ret;
            this.precargar();
        });//el /0 es porque no tengo pais para filtrar
          }
  }
  locaSeleccionada(data){
    if(data && data.option && data.option.value && data.option.value.loca_Codigo){
    this.form.get('fide_DomiLocalidad').setValue(data.option.value.loca_Codigo);
    this.form.get('fide_DomiCodigoPostal').setValue(data.option.value.loca_CodigoPostal);
    //this.asm.usuario.localidad = data.option.value;
          }
    else
    {
      this.form.get('fide_DomiLocalidad').setValue(undefined);
      this.form.get('fide_DomiCodigoPostal').setValue(undefined);
    }
  }
  precargar() {
    //return;
    this.form.get('fide_NumeroDoc').setValue(this.asm.usuario.fide_NumeroDoc)
    this.form.get('fide_Apellido').setValue(this.asm.usuario.fide_Apellido)
    this.form.get('fide_Nombre').setValue(this.asm.usuario.fide_Nombre)
    this.form.get('fide_email').setValue(this.asm.usuario.fide_email)
    this.form.get('fide_TelMovil').setValue(this.asm.usuario.fide_TelMovil)
    //this.form.get('fide_TelFijo').setValue(this.asm.usuario.fide_TelFijo)
    this.form.get('fide_Nacimiento').setValue(this.asm.usuario.fide_Nacimiento)
    this.form.get('fide_DomiLocalidad').setValue(this.asm.usuario.fide_DomiLocalidad && this.asm.usuario.fide_DomiLocalidad != '' ? parseInt(this.asm.usuario.fide_DomiLocalidad) : undefined)
    this.form.get('pcia_Codigo').setValue(this.asm.usuario.pcia_Codigo)
    this.form.get('fide_DomiCodigoPostal').setValue(this.asm.usuario.fide_DomiCodigoPostal)
    this.form.get('fide_RecibeNovedades').setValue(this.asm.usuario.fide_RecibeNovedades == 'S')
    this.form.get('localidadesAutocomplete').setValue(this.asm.usuario.localidad)

    this.localidadesFiltradas = this.form.get('localidadesAutocomplete').valueChanges
    .pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        let val = value;
        if(val){
          if(value.loca_Codigo){
            val = value.loca_Descripcion;
          }
            return this.asm.post("LocalidadesConFiltro",{ loca_Descripcion : val, pcia_Codigo : this.form.get('pcia_Codigo').value});
        }
      else
          return [];
      })
    );
    this.form.get('pcia_Codigo').valueChanges.subscribe(value => {
      if(value != this.asm.usuario.pcia_Codigo){
        this.form.get('localidadesAutocomplete').patchValue(undefined);
        this.locaSeleccionada(undefined);
      }
    });
    this.cd.markForCheck();
  }
  localidadSeleccionada : Localidades = undefined;
  localidadesFiltradas : Observable<Localidades[]> ; 
  displayLoca(found?: Localidades): string | undefined {
    return found ? found.loca_Descripcion + ' ('+found.loca_CodigoPostal+')'  : '';
  }
  filterLoca(val: any) {
    if(this.loadInicialProvincias){
      if(this.asm.usuario.localidad){
        val = this.asm.usuario.localidad.loca_Descripcion;
      }
      this.loadInicialProvincias = false;
      return;
    }
    if(!val && this.form.get('localidadesAutocomplete').value != undefined){
      this.form.get('localidadesAutocomplete').patchValue(undefined);
      this.locaSeleccionada(undefined);
      return [];
    }
    if(!val && this.form.get('localidadesAutocomplete').value == undefined){
      return [];
    }
    let filtrostring = val.loca_Descripcion || val; 
    var ret = this.localidades.filter(option => option.loca_Descripcion.toLowerCase().indexOf(filtrostring.toLowerCase()) >=0);
    if(ret.length == 0 && this.form.get('localidadesAutocomplete').value != undefined){
        this.form.get('localidadesAutocomplete').patchValue(undefined);
        this.locaSeleccionada(undefined);
      }
    return ret;
  }

  modelo: Fidelizaciones = new Fidelizaciones();
  guardar() {
    this.modelo.fide_NumeroDoc = this.form.get('fide_NumeroDoc').value;
    this.modelo.fide_Apellido = this.form.get('fide_Apellido').value;
    this.modelo.fide_Nombre = this.form.get('fide_Nombre').value;
    this.modelo.fide_email = this.form.get('fide_email').value;
    this.modelo.fide_TelMovil = this.form.get('fide_TelMovil').value;
    //this.modelo.fide_TelFijo = this.form.get('fide_TelFijo').value;
    this.modelo.fide_Nacimiento = this.form.get('fide_Nacimiento').value;
    this.modelo.fide_DomiLocalidad = this.form.get('fide_DomiLocalidad').value;
    this.modelo.fide_DomiCodigoPostal = this.form.get('fide_DomiCodigoPostal').value;
    this.modelo.pcia_Codigo = this.form.get('pcia_Codigo').value;
    this.modelo.fide_RecibeNovedades = this.form.get('fide_RecibeNovedades').value ? "S" : "N";
    this.asm.usuario.localidad = this.form.get('localidadesAutocomplete').value ;
    this.asm.post("Usuario_ActualizarDatos", this.modelo).subscribe((ret) => {
      this.asm.usuario = this.modelo;
    this.asm.usuario.localidad = this.form.get('localidadesAutocomplete').value ;
      this.noti.warn("Se actualizaron los datos de tu perfil!");
      this.formPass.markAsPristine();
      this.cd.markForCheck();
    },
      (err) => {
        this.asm.requesting--; this.cd.markForCheck();
        if (err.error && err.error.Mensaje)
          this.noti.warn(err.error.Mensaje);
      }
    )

  }

  allowedChars = new Set('0123456789'.split(''));

  checkTel(event: KeyboardEvent) {
    if(event.key == 'Tab' || event.key == 'Delete' || event.key == 'Backspace' || event.key.indexOf('Arrow')>= 0  ){return;}
    if (! this.allowedChars.has(event.key)) {
      event.preventDefault();
    }
  }
  
  @ViewChild("formDirective") formDirective;
  cambiarClave() {

    this.asm.post("Usuario_CambiarClave", {
      fide_ClaveAccesoAnterior: this.formPass.get('fide_ClaveAccesoAnterior').value,
      fide_ClaveAcceso: this.formPass.get('fide_ClaveAcceso').value,
      fide_ClaveAcceso2: this.formPass.get('fide_ClaveAcceso2').value
    }).subscribe((ret) => {
      this.noti.warn("La contraseña se modificó");
      this.formPass.reset();
	 this.formDirective.resetForm();
	 this.formPass.markAsPristine();
      this.formPass.markAsUntouched();
      this.formPass.updateValueAndValidity();
      this.cd.markForCheck();
    },
      (err) => {
        this.asm.requesting--; this.cd.markForCheck();
        if (err.error && err.error.Mensaje)
          this.noti.warn(err.error.Mensaje);
      }
    )

  }
}
