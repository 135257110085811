import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLocaleData } from '@angular/common';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


import localeEs from '@angular/common/locales/es';
import localeARExtra from '@angular/common/locales/extra/es-AR';

registerLocaleData(localeEs, 'es-AR', localeARExtra);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{provide: LOCALE_ID, useValue: 'es-AR' }]
  //cambio
});