import browser from 'browser-detect';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  ActionAuthLogin,
  ActionAuthLogout,
  routeAnimations,
  AppState,
  LocalStorageService,
  selectIsAuthenticated
} from '@app/core';
import { environment as env } from '@env/environment';

import {
  ActionSettingsChangeLanguage,
  ActionSettingsChangeAnimationsPageDisabled,
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectSettingsStickyHeader
} from './settings';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MainService, EventosDeSistem } from './core/main.service';

@Component({
  selector: 'fidelizacion-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = require('../assets/logo.png');
  languages = ['en', 'es'];
  navigationPrivados = [
    {
      url: "tarjeta",
      titulo: 'Tarjeta',
      subtitulo: 'Accedé a tu credencial virtual',
      area: 'MIEMBROS',
      seccion: 'TARJETA',
      banner_seccion: 'HEADER_TARJETA'
    },
    {
      url: "perfil",
      titulo: 'Perfil',
      subtitulo: 'Administrá tus datos personales',
      area: 'MIEMBROS',
      seccion: 'PERFIL',
      banner_seccion: 'HEADER_PERFIL'
    },
    {
      url: "compras",
      titulo: 'Mis Compras',
      subtitulo: 'Consultá tu actividad de compras',
      area: 'MIEMBROS',
      seccion: 'COMPRAS',
      banner_seccion: 'HEADER_COMPRAS'
    },
    {
      url: "puntos",
      titulo: 'Puntos',
      subtitulo: 'Consultá tu estado de cuenta y puntos obtenidos',
      area: 'MIEMBROS',
      seccion: 'PUNTOS',
      banner_seccion: 'HEADER_PUNTOS'
    },
    {
      url: "vencimientos",
      titulo: 'Vencimientos',
      subtitulo: 'Consultá los vencimientos individuales de tus puntos',
      area: 'MIEMBROS',
      seccion: 'VENCIMIENTOS',
      banner_seccion: 'HEADER_VENCIMIENTOS'
    },
    {
	url: "contacto",
	titulo: 'Contacto',
	subtitulo: 'Dejá acá tu consulta',
	area: 'MIEMBROS',
	seccion: 'MIEMBROS',
	banner_seccion: 'HEADER_CONTACTO'
   },    {
	url: "cupones",
	titulo: 'Cupones',
	subtitulo: 'Consultá tus cupones',
	area: 'MIEMBROS',
	seccion: 'MIEMBROS',
	banner_seccion: 'HEADER_CUPONES'
   },
  ];
  public itemLogin = {
    url: "login",
    titulo: 'Iniciar Sesión',
    subtitulo: 'Consultá la información de tu tarjeta y datos personales',
    area: 'MIEMBROS',
    seccion: 'LOGIN',
    banner_seccion: 'HEADER_INICIARSESION',
  };
  public itemRegistracion =  {
    url: "registrar",
    titulo: 'Registrarme',
    subtitulo: 'Participá de Desiderata ReLoad',
    area: 'MIEMBROS',
    seccion: 'REGISTRACION',
    banner_seccion: 'HEADER_REGISTRARME',
  };

  navigation = [
      ...this.navigationPrivados,this.itemLogin, this.itemRegistracion
    ];

  isAuthenticated$: Observable<boolean>;
  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: Observable<string>;
  banner_seccion: string;

   constructor(
    private store: Store<AppState>,
    private router: Router,
    public asm: MainService,
    private cd: ChangeDetectorRef,
    private ar: ActivatedRoute,
    private storageService: LocalStorageService
  ) {

    this.asm.sysevent.subscribe((evento: EventosDeSistem) => {
      if (evento == EventosDeSistem.Logout) {
        this.router.navigate(['/login']);
      }
    });

    if (this.asm.access_token && this.asm.access_token != '') {

      this.asm.obtenerUsuariosDatos().subscribe(() => { }, (err) => this.asm.logout());
    }

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        let itemSeleccionado = this.navigation.find(u => '/' + u.url == e.url || (e.url.indexOf('registrar') >= 0 && u.url.indexOf('registrar') >= 0));
        if(itemSeleccionado){
        this.titulo = itemSeleccionado.titulo;
        this.subtitulo = itemSeleccionado.subtitulo;
        this.area = itemSeleccionado.area;
        this.seccion = itemSeleccionado.seccion;
        this.banner_seccion = itemSeleccionado.banner_seccion;
      }
      }
    })

  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);


  }


  titulo: string;
  subtitulo: string;
  seccion: string = 'INICIO';
  area: string = 'INICIO';
  ngOnInit(): void {
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        new ActionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }


    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));
  }

  onLoginClick() {
    this.store.dispatch(new ActionAuthLogin());
  }

  onLogoutClick() {
    this.asm.logout();
    this.cd.markForCheck();
    this.router.navigate(['/login']);
  }
  irAperfil() {
    this.router.navigate(['/perfil']);
  }
  onLanguageSelect({ value: language }) {
    this.store.dispatch(new ActionSettingsChangeLanguage({ language }));
  }
}
