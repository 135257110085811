import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NotificationService } from '@app/core';
import { MainService } from '@app/core/main.service';


@Component({
	selector: 'cupon-item',
	templateUrl: './cupon_item.component.html',
	styleUrls: ['./cupon_item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CuponItemComponent implements OnInit {
	@Input("cupon") cupon;
	@Input("modo") modo;
	@Input("saldoActual") saldoActual : number = 0;
	constructor(public asm: MainService, private noti: NotificationService, private cd: ChangeDetectorRef, private dialog : MatDialog) {

	}
	ngOnInit() {
	}


}


