import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';

import { ROUTE_ANIMATIONS_ELEMENTS, NotificationService } from '@app/core';
import { MainService } from '@app/core/main.service';
import { MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'fidelizacion-puntos',
  templateUrl: './puntos.component.html',
  styleUrls: ['./puntos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PuntosComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  displayedColumns: string[] = ['fecha','sucu','tipo','comp', 'puntos','saldo'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(public asm: MainService, private noti: NotificationService, private cd: ChangeDetectorRef) {
    this.datos
  }

  datos : any;
  saldo : any;
  consumidos : any;
  proxVto : string;
  puntosProxVto : any;
ngOnInit() {

    this.asm.post("Puntos_Listar",undefined).subscribe((ret) => {
      this.datos = ret.movimientos;
      this.consumidos = ret.consumidos;
      this.saldo = ret.saldo;
      this.puntosProxVto = ret.puntosProxVto;
      this.proxVto = ret.proxVto;
      this.cd.markForCheck();
    },
      (err) => {
        this.asm.requesting--; 
        if (err.error && err.error.Mensaje)
          this.noti.warn(err.error.Mensaje);
      }
    )


  }
}
