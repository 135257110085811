import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationService, ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MainService, auxLoginSignup } from '@app/core/main.service';
import { Fidelizaciones } from '@app/core/modelo/modelo.negocio';

@Component({
  selector: 'fidelizacion-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrarComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  nuevo: auxLoginSignup = new auxLoginSignup();
  enviado: boolean = false;

  modelo: Fidelizaciones
  @ViewChild('myForm') myForm: NgForm;
  form = this.fb.group({
    fide_NumeroDoc: ['', [Validators.required, Validators.maxLength(8), Validators.max(99999999)]],
    fide_Apellido: ['', [Validators.required]],
    fide_Nombre: ['', [Validators.required]],
    fide_email: ['', [Validators.required, Validators.email]],
    fide_TelMovil: ['', [Validators.required]],
    fide_Nacimiento: ['', [Validators.required]],
    //fide_TelFijo: ['', []],
    //fide_LifeStyle: ['', []],
    //fide_MotivoCompra: ['', []],
    fide_RecibeNovedades: [true],
    aceptoTerminos: ['', [Validators.requiredTrue]],
    fide_ClaveAcceso: ['', [Validators.required]],
    fide_ClaveAcceso2: ['', [Validators.required]],
  });

  constructor(private fb: FormBuilder, private ar: ActivatedRoute,
    public asm: MainService, private noti: NotificationService, private cd: ChangeDetectorRef) {
  }


  ngOnInit() {

    this.asm.esInvitacion = true;
    let invi = this.ar.snapshot.paramMap.get('invi');

    if (invi) {
      this.asm.post("VerDatosInvitacion/" + invi, {}).subscribe((ret) => {
        this.form.get('fide_email').setValue(ret.email);
        this.form.get('fide_Apellido').setValue(ret.apellido);
        this.form.get('fide_Nombre').setValue(ret.nombre);
        this.form.get('fide_NumeroDoc').setValue(ret.documento);
        this.nuevo.apellido = this.form.get('fide_Apellido').value;
        this.nuevo.email = this.form.get('fide_email').value;
        this.nuevo.nombre = this.form.get('fide_Nombre').value;
        this.nuevo.documento = this.form.get('fide_NumeroDoc').value;
	   this.nuevo.programa = this.asm.programa;
	   this.nuevo.canal = 'WEB';

        this.form.get('fide_email').disable();
        if (this.nuevo.documento) {
          this.form.get('fide_NumeroDoc').disable();
        }

        this.cd.markForCheck();
      },
        (err) => {
          this.asm.requesting--;
          this.cd.markForCheck();
          if (err.error && err.error.Mensaje)
            this.noti.warn(err.error.Mensaje);
        }
      )
    }
  }



  borrar() {
    this.form.reset();
    this.form.get('fide_RecibeNovedades').setValue(true);
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].setErrors(null)
    });
  }

  registrar() {
    this.nuevo.email = this.form.get('fide_email').value;
    this.nuevo.nombre = this.form.get('fide_Nombre').value;
    this.nuevo.apellido = this.form.get('fide_Apellido').value;
    this.nuevo.telmovil = this.form.get('fide_TelMovil').value;
    this.nuevo.nacimiento = this.form.get('fide_Nacimiento').value;
   // this.nuevo.lifeStyle = this.form.get('fide_LifeStyle').value;
    //this.nuevo.motivoCompra = this.form.get('fide_MotivoCompra').value;
    //this.nuevo.telfijo = this.form.get('fide_TelFijo').value;
    this.nuevo.clave = this.form.get('fide_ClaveAcceso').value;
    this.nuevo.recibirNovedades = this.form.get('fide_RecibeNovedades').value;
    this.nuevo.documento = this.form.get('fide_NumeroDoc').value;
    this.nuevo.programa = this.asm.programa;
	this.nuevo.canal = "WEB"
	
;    this.asm.post("Usuario_Registrar", this.nuevo).subscribe((ret) => {
      this.enviado = true;
      this.cd.markForCheck();
    },
      (err) => {
        this.asm.requesting--;
        this.cd.markForCheck();
        if (err.error && err.error.Mensaje)
          this.noti.warn(err.error.Mensaje);
      }
    )
  }

  allowedChars = new Set('0123456789'.split(''));

  checkTel(event: KeyboardEvent) {
    // 31 and below are control keys, don't block them.
    if(event.key == 'Tab' || event.key == 'Delete' || event.key == 'Backspace' || event.key.indexOf('Arrow')>= 0  ){return;}
    if (!this.allowedChars.has(event.key)) {
      event.preventDefault();
    }
  }
}
