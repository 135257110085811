import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationService } from '../notifications/notification.service';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private notificationsService: NotificationService) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    let displayMessage = 'Ocurrió un error de comunicación con el servidor';

    if (environment.production) {
      displayMessage += ' Intente iniciar sesión nuevamente.';
    }

    this.notificationsService.error(displayMessage);

    super.handleError(error);
  }
}
